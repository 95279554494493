import { useEffect, useRef, useState } from "react";
import { format } from "date-fns";
import Button from "../Button/Button";
import Cancel from "../picture/Cancel.png";
import Back from "../picture/Back.png";
import Sort from "../picture/Sort.png";
import Search from "../picture/Search.png";
import Share from "../picture/Share.png";
import Plus from "../picture/Plus.png";
import Send from "../picture/Send.png";
import Message from "../picture/Message.png";
import Mail from "../picture/Mail.png";
import axios from "axios";
import "./link.css";
import { useGlobalContext } from "../Context/Context";
import BackLink from "../Links/ComeLink";
import question from "../picture/question.png";
import dots from "../picture/dots.png";
import ForwardMessage from "../Chat/ForwardMessage";

const Link = ({ setSelectAccount, currentTab, setCheckBack }) => {
  const [myLinks, setMyLinks] = useState([]);
  const [openLink, setOpenLink] = useState(false);
  const [openName, setOpenName] = useState(false);
  const [goToLink, setGoToLink] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [isForward, setIsForward] = useState(false);
  const [goToMyLink, setGoToMyLink] = useState();
  const [page, setPage] = useState(1);
  const [localChat, setLocalChat] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState({
    value: false,
    data: "",
  });

  const perf = "../OpenLink/index.html";
  console.log(perf);
  const { apiKey, baseUrl, loggedUser, letterSize, boldItalic } =
    useGlobalContext();
  const { typeButton, fontSize, fontStyle } = useGlobalContext();

  const linkRef = useRef();
  const nameRef = useRef();
  const searchRef = useRef();

  const handleOpenLink = () => {
    setOpenLink(!openLink);
    if (openLink) {
      setOpenName(false);
    }
  };

  const handleDeleteLink = async (index) => {
    try {
      console.log(loggedUser.id);
      console.log(apiKey);
      console.log(parseInt(index));
      const { data } = await axios.post(`${baseUrl}/user_links/delete.php`, {
        link_id: index,
        api_key: apiKey,
      });
      console.log(data);
      if (data.status === 200) {
        const myNewLinks = myLinks.filter((item) => item.id !== index);
        setMyLinks(myNewLinks);
      }
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeLink = (e) => {
    linkRef.current = e.currentTarget.value;
  };

  const handleInputName = (e) => {
    nameRef.current = e.currentTarget.value;
  };

  const handleGoToLink = (myLink, index) => {
    setGoToMyLink(myLink);
    setGoToLink(true);

    const IFrame = document.querySelector(".wrapper-link-content");
    console.log(IFrame);
    //IFrame.src= `https://${goToMyLink.link}`
    /* const corsAnywhere = "https://cors-anywhere.herokuapp.com/";
    fetch(`https://${myLink.link}`)
      .then((response) => response.text())
      .then((data) => {
        myDiv.innerHTML = data;
      })
      .catch((error) => console.error("Error fetching data:", error));
  */
  };

  const handleShareLink = () => {
    setOpenShare(!openShare);
  };

  const handleCloseLink = () => {
    setGoToLink(false);
    setGoToMyLink();
    setOpenShare(false);
  };

  const handleAddLink = async () => {
    if (openName) {
      try {
        console.log(nameRef.current);
        console.log(apiKey);
        const { data } = await axios.post(`${baseUrl}/user_links/create.php`, {
          user_id: loggedUser.id,
          api_key: apiKey,
          name: nameRef.current,
          link: linkRef.current,
        });
        console.log(data);
        if (data.status === 200) {
          setMyLinks([
            {
              id: data.link_id,
              name: nameRef.current,
              link: linkRef.current,
            },
            ...myLinks,
          ]);
          nameRef.current = "";
          linkRef.current = "";
          setOpenLink(false);
          setOpenName(false);
        }
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      setOpenName(true);
    }
  };

  const handleOpenMail = () => {
    window.location.href = `mailto:?subject=ShareLink&body=${goToMyLink.link}`;
  };

  const handleOpenSearch = () => {
    setGoToLink(!goToLink);
    setOpenSearch(!openSearch);
  };

  const handleSearchTerm = (e) => {
    searchRef.current = e.currentTarget.value;
  };

  const handleFindLinkSearch = () => {
    console.log("Load iframe");
  };

  const handleFindLink = (e) => {
    const IFrame = e.currentTarget;
    console.log(IFrame);
    console.log(IFrame.querySelectorAll("*"));
  };

  const handleOpenShare = async () => {
    console.log("Otvaram share");
    const shareData = {
      title: goToMyLink.name,
      url: goToMyLink.link,
    };
    try {
      await navigator.share(shareData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleConvertText = (text) => {
    console.log(text);
    if (letterSize === undefined || letterSize === null) {
      return text;
    }
    if (letterSize === 0) {
      return text.toUpperCase();
    }
    if (letterSize === 1) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    }
    if (letterSize === 2) {
      return text.toLowerCase();
    }
  };

  const handleSearchTermFromInput = async () => {
    try {
      console.log(searchRef.current);
      console.log(page);
      const { data } = await axios.post(
        `${baseUrl}/user_links/searchLink.php`,
        {
          keyword: searchRef.current,
          page: page,
        }
      );
      console.log(data);
      if (data.status === 200) {
        const IFrame = document.querySelector(".wrapper-link-search-content");
        //console.log(IFrame)
        console.log(BackLink);
        if (IFrame !== null && IFrame !== undefined) IFrame.srcdoc = BackLink;
      }
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenMessage = () => {
    console.log("Otvaram poruku deljenje", goToMyLink);
    setIsForward(true);
    setSelectedMessage({
      value: true,
      data: {
        id: Date.now(), // Assign a unique ID temporarily for rendering
        sender_id: loggedUser.id,
        sender:
          loggedUser.name === "" ? loggedUser.user_email : loggedUser.name,
        text: goToMyLink?.link,
        date: format(new Date(), "dd.MM.yyyy HH:mm"),
        reply: "",
      },
    });
  };

  useEffect(() => {
    const handleBackLink = async () => {
      try {
        console.log(loggedUser);
        console.log(apiKey);
        const { data } = await axios.post(
          `${baseUrl}/user_links/readByUserId.php`,
          {
            user_id: loggedUser.id,
            api_key: apiKey,
          }
        );
        console.log(data);
        if (data.status === 200) {
          setMyLinks(data.data);
        } else {
          setMyLinks([]);
        }
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    };
    handleBackLink();
    nameRef.current = "";
    linkRef.current = "";
  }, []);

  useEffect(() => {
    const IFrame = document.querySelector(".wrapper-link-content");
    console.log(IFrame);
    if (IFrame !== null && IFrame !== undefined)
      IFrame.src = `https://${goToMyLink?.link}`;
  }, [goToLink]);

  console.log("Link za slanje", goToMyLink);
  return (
    <div className="wrapper-container">
      <div
        className={
          !openSearch && goToLink
            ? "wrapper-header correction-header"
            : "wrapper-header"
        }
      >
        {!goToMyLink ? (
          <>
            <div className="wrapper-button" onClick={() => setSelectAccount(0)}>
              <Button typeButton={typeButton}>
                <img src={Back} alt="" className="visible-img" />
              </Button>
            </div>
            <div
              className="wrapper-button"
              onClick={() => setMyLinks([...myLinks.reverse()])}
            >
              <Button typeButton={typeButton}>
                <img src={Sort} alt="" className="visible-img" />
              </Button>
            </div>
            <div className="wrapper-button" onClick={handleOpenSearch}>
              <Button typeButton={typeButton}>
                <img src={Search} alt="" className="visible-img" />
              </Button>
            </div>
            <div className="wrapper-button" onClick={handleOpenLink}>
              <Button active={openLink} typeButton={typeButton}>
                <img src={Plus} alt="" className="visible-img" />
              </Button>
            </div>
          </>
        ) : !openSearch ? (
          <div className="one-link open">
            <div className="wrapper-my-link-button" onClick={handleCloseLink}>
              <Button
                active={goToLink}
                typeButton={typeButton}
                className={
                  `font-size-${parseInt(
                    fontSize === undefined || fontSize === null
                      ? 0
                      : fontSize / 3
                  )}` +
                  ` font-style-${parseInt(
                    fontStyle === undefined || fontStyle === null
                      ? 0
                      : fontStyle / 3
                  )}`
                }
                style={
                  boldItalic.find((element) => parseInt(element) === 2) !==
                  undefined
                    ? {
                        fontWeight: "normal",
                        fontStyle: "normal",
                      }
                    : boldItalic.find((element) => parseInt(element) === 1) !==
                        undefined &&
                      boldItalic.find((element) => parseInt(element) === 0) !==
                        undefined
                    ? {
                        fontWeight: "bold",
                        fontStyle: "italic",
                      }
                    : boldItalic.find((element) => parseInt(element) === 0) !==
                      undefined
                    ? {
                        fontStyle: "italic",
                      }
                    : boldItalic.find((element) => parseInt(element) === 1) !==
                      undefined
                    ? {
                        fontWeight: "bold",
                      }
                    : {
                        fontWeight: "normal",
                        fontStyle: "normal",
                      }
                }
              >
                {handleConvertText(
                  goToMyLink.name !== "" ? goToMyLink.name : goToMyLink.link
                )}
              </Button>
            </div>
            <div
              className="wrapper-link-cancel"
              onClick={() => handleShareLink()}
            >
              <Button active={openShare} typeButton={typeButton}>
                <img src={Share} alt="" className="visible-img" />
              </Button>
            </div>
          </div>
        ) : (
          <span
            className={
              `font-size-${parseInt(
                fontSize === undefined || fontSize === null ? 0 : fontSize / 3
              )}` +
              ` font-style-${parseInt(
                fontStyle === undefined || fontStyle === null
                  ? 0
                  : fontStyle / 3
              )}`
            }
            style={
              boldItalic.find((element) => parseInt(element) === 2) !==
              undefined
                ? {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                    undefined &&
                  boldItalic.find((element) => parseInt(element) === 0) !==
                    undefined
                ? {
                    fontWeight: "bold",
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
                ? {
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined
                ? {
                    fontWeight: "bold",
                  }
                : {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
            }
          >
            {handleConvertText("Naslov search")}
          </span>
        )}
      </div>
      <div className="wrapper-content">
        {!goToLink ? (
          myLinks.map((item, index) => {
            return (
              <div className="one-link" key={index}>
                <div
                  className="wrapper-my-link-button"
                  onClick={() => handleGoToLink(item, index)}
                >
                  <Button
                    active={goToLink}
                    typeButton={typeButton}
                    className={
                      `font-size-${parseInt(
                        fontSize === undefined || fontSize === null
                          ? 0
                          : fontSize / 3
                      )}` +
                      ` font-style-${parseInt(
                        fontStyle === undefined || fontStyle === null
                          ? 0
                          : fontStyle / 3
                      )}`
                    }
                    style={
                      boldItalic.find((element) => parseInt(element) === 2) !==
                      undefined
                        ? {
                            fontWeight: "normal",
                            fontStyle: "normal",
                          }
                        : boldItalic.find(
                            (element) => parseInt(element) === 1
                          ) !== undefined &&
                          boldItalic.find(
                            (element) => parseInt(element) === 0
                          ) !== undefined
                        ? {
                            fontWeight: "bold",
                            fontStyle: "italic",
                          }
                        : boldItalic.find(
                            (element) => parseInt(element) === 0
                          ) !== undefined
                        ? {
                            fontStyle: "italic",
                          }
                        : boldItalic.find(
                            (element) => parseInt(element) === 1
                          ) !== undefined
                        ? {
                            fontWeight: "bold",
                          }
                        : {
                            fontWeight: "normal",
                            fontStyle: "normal",
                          }
                    }
                  >
                    <span class="link-span-inner">
                      {handleConvertText(
                        item.name !== "" ? item.name : item.link
                      )}
                    </span>
                  </Button>
                </div>
                <div
                  className="wrapper-link-cancel"
                  onClick={() => handleDeleteLink(item.id)}
                >
                  <Button activation={true} typeButton={typeButton}>
                    <img src={Cancel} alt="" className="visible-img" />
                  </Button>
                </div>
              </div>
            );
          })
        ) : !openSearch ? (
          <iframe
            className="wrapper-link-content"
            title=""
            onLoad={handleFindLink}
          ></iframe>
        ) : (
          <div className="wrapper-search-content">
            <div className="wrapper-search-header">
              <input onChange={handleSearchTerm}></input>
              <div
                className="wrapper-search-button"
                onClick={handleSearchTermFromInput}
              >
                <Button typeButton={typeButton}>
                  <img src={Search} alt="" className="visible-img" />
                </Button>
              </div>
            </div>
            <div className="wrapper-search-body">
              <iframe
                className="wrapper-link-search-content"
                title=""
                onLoad={handleFindLinkSearch}
              ></iframe>
            </div>
          </div>
        )}
        {openLink && !goToLink && (
          <div className="wrapper-input-link">
            <input type="text" onChange={handleChangeLink} />
            <div className="wrapper-link-cancel" onClick={handleAddLink}>
              <Button activation={true} typeButton={typeButton}>
                <img
                  src={openName ? Send : Plus}
                  alt=""
                  className="visible-img"
                />
              </Button>
            </div>
          </div>
        )}
        {openName && !goToLink && (
          <input
            type="text"
            className="input-name-link"
            onChange={handleInputName}
          />
        )}
      </div>
      {openShare && (
        <div className="wrapper-open-share">
          <div className="wrapper-link-share-button" onClick={handleOpenShare}>
            <Button activation={true} typeButton={typeButton}>
              <div className="image-dots">
                <img style={{ height: "10%" }} src={dots}></img>
              </div>
            </Button>
          </div>
          <div className="wrapper-link-share-button">
            <Button activation={true} typeButton={typeButton}>
              <img src={question} alt="" className="visible-img" />
            </Button>
          </div>
          <div className="wrapper-link-share-button" onClick={handleOpenMail}>
            <Button activation={true} typeButton={typeButton}>
              <img src={Mail} alt="" className="visible-img" />
            </Button>
          </div>
          <div
            className="wrapper-link-share-button"
            onClick={() => handleOpenMessage()}
          >
            <Button activation={true} typeButton={typeButton}>
              <img src={Message} alt="" className="visible-img" />
            </Button>
          </div>
        </div>
      )}
      {isForward && (
        <ForwardMessage
          setSelectedMessage={setSelectedMessage}
          setIsForward={setIsForward}
          selectedMessage={selectedMessage}
          localChat={localChat}
          setLocalChat={setLocalChat}
          currentTab={currentTab}
          setSelectAccount={setSelectAccount}
          setCheckBack={setCheckBack}
        />
      )}
    </div>
  );
};

export default Link;
