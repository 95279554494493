import { useEffect, useRef, useState } from "react";
import "./pinUnlockLog.css";
import { ContentPasteOffSharp } from "@mui/icons-material";
import { useReducer } from "react";
import axios from "axios";
import { useGlobalContext } from "../Context/Context";

const PinUnlockLog = ({
  height,
  width,
  setInputPattern,
  draft,
  resetSwitch,
  setResetSwitch,
  setCurrentPage,
}) => {
  const arrayPin = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const remaining = useRef(3);
  const [currentPin, setCurrentPin] = useState("");
  const { baseUrl } = useGlobalContext();
  const { loggedUser } = useGlobalContext();
  const { apiKey } = useGlobalContext();
  console.log(remaining.current);
  const handleChoosePin = (number, e) => {
    if (draft) {
      if (currentPin.length >= 6) {
        const pins = document.querySelectorAll(".number-choose-pin-log");
        const currentPinNew = currentPin + (number - 1);
        const split_string = currentPinNew.split("")

        let pinExist = [];
        pins.forEach((item) => {
          const newPin = split_string.find(
            (pinNew) => parseInt(item.innerHTML) === parseInt(pinNew) + 1
          );
          if (newPin !== undefined) {
            pinExist.push(item);
          }
        });

        pinExist.forEach((pinDiv) => {
          pinDiv.style.backgroundColor = "red";
        });
        remaining.current = remaining.current - 1;
        let timeOut = setTimeout(() => {
          pinExist.forEach((pinDiv) => {
            pinDiv.style.backgroundColor = "gray";
          });

          setResetSwitch(true);
        }, 1000);
        return;
      }

      setInputPattern((prev) => [...prev, number - 1]);
      setCurrentPin(currentPin + (number - 1));
      e.currentTarget.style.backgroundColor = "gray";
    }
  };
  const resetCurrentPin = () => {
    setCurrentPin("");
  };

  const handleDeletePin = () => {
    resetCurrentPin();
  };

  const handleOK = async () => {
    if (currentPin.length < 4) return;
    try {
      const { data } = await axios.post(
        `${baseUrl}/auth/checkUserPattern.php`,
        {
          user_id: loggedUser.id,
          api_key: apiKey,
          user_pattern: currentPin,
        }
      );

      if (data.status === 200) {
        const currentPinDots = document.querySelectorAll(
          ".current-pin-dot-log"
        );
        currentPinDots.forEach((element) => {
          element.classList.add("animated");
        });
        setTimeout(resetCurrentPin, 1000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleKeyPress = (e) => {
    // enter
    if (e.keyCode === 13) handleOK();
    // brojevi i brojevi na Num pad-u
    else if (
      (e.keyCode >= 48 && e.keyCode <= 57) ||
      (e.keyCode >= 96 && e.keyCode <= 105)
    )
      handleChoosePin(parseInt(e.key));
  };

  const handleClick = () => {
    const wrapperPinUnlock = document.querySelector(".wrapper-pin-unlock-log");
    wrapperPinUnlock.focus();
  };

  useEffect(() => {
    if (draft) {
      window.addEventListener("click", handleClick);

      return () => {
        window.removeEventListener("click", handleClick);
      };
    }
  }, []);

  useEffect(() => {
    if (resetSwitch) {
      const dots = document.querySelectorAll(".number-choose-pin-log");
      dots.forEach((dot) => (dot.style.backgroundColor = "#fff"));
      setResetSwitch(false);
      setCurrentPin("");
      setInputPattern([]);
      if (remaining.current === 0) {
        setCurrentPage(7);
      }
    }
  }, [resetSwitch]);

  return (
    <div
      className="unlock-screen-log"
      style={{ height: `${height}px`, width: `${width}px` }}
    >
      <div
        className="wrapper-pin-unlock-log"
        tabIndex={0}
        onKeyDown={handleKeyPress}
      >
        <div className="wrapper-pin-choose-element-log">
          {arrayPin.map((number, index) => {
            return (
              <div
                className="number-choose-pin-log"
                key={index}
                onClick={(e) => handleChoosePin(number, e)}
              >
                {" "}
                {number}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PinUnlockLog;
