import Button from "../Button/Button";
import sendImg from "./images/slanje.png";
import arrowImg from "./images/play-left.png";
import headphonesImg from "./images/headphone.png";
import locationImg from "./images/location.png";
import cvImg from "./images/CV.png";
import plusImg from "./images/plus.png";
import { useRef, useState } from "react";
import SelectBox from "./SelectBox";
import axios from "axios";
import { useGlobalContext } from "../Context/Context";
import "./profile.css";

const ProfileSelect = ({
  setCurrentPage,
  userData,
  setUserData,
  setSelectAccount,
}) => {
  const {
    typeButton,
    letterSize,
    boldItalic,
    fontSize,
    fontStyle,
    loggedUser,
    apiKey
  } = useGlobalContext();
  console.log(userData);
  const dataNames = [
    "Stepen kvalifikacije",
    "Profesija",
    "Oblast rada",
    "Radno iskustvo",
    "Strani jezici",
  ];
  const selectedItemsStart = {
    selected: false,
    value: -1,
    items: [],
  };

  const [selectedItems, setSelectedItems] = useState(selectedItemsStart);
  const [inputActive, setInputActive] = useState({
    selected: false,
    value: -1,
  });

  const inputRef = useRef();

  const handleClickBtn = async () => {
    const baseUrl = "https://data.nadzor.app/api/";
    const url = baseUrl + "profiles/insertProfileData.php";
    const requestObject = {
      user_id: loggedUser.id,
      api_key: apiKey,
      up_stepen_kvalifikacije: userData.data.qualification,
      up_profesija: userData.data.profession,
      up_oblast_rada: userData.data.workField,
      up_radno_iskustvo: userData.data.workExperience,
      up_adresa: userData.address,
      up_broj: userData.phone,
      cv: userData.cv,
      languages: userData.data.languages,
    };
    const { data } = await axios.post(url, requestObject);
    console.log(data);
    //setCurrentPage(2);
    setSelectAccount(loggedUser.defaultHomePage);
  };

  const handleGetItems = async (index) => {
    const baseUrl = "https://data.nadzor.app/api/";
    let url;
    if (index === 0) url = baseUrl + "qualifications/read.php";
    else if (index === 1) url = baseUrl + "professions/read.php";
    else if (index === 2) url = baseUrl + "areas/read.php";
    else if (index === 3) url = baseUrl + "work_experience/read.php";
    else url = baseUrl + "foreign_languages/read.php";
    const requestObject = {
      user_id: 1,
      api_key:
        "c4222563ed7114b9ed64f5bd3120f5cdb1a8aac9f98e7cf7bda1049d54e4c168",
    };
    const { data } = await axios.post(url, requestObject);

    const newData = data.data.map((item) => {
      const values = Object.values(item);
      return {
        id: values[0],
        name: values[1],
      };
    });
    setSelectedItems({
      value: index,
      selected: true,
      items: newData,
    });
  };

  const handleInputFile = (e) => {
    const currentFile = e.currentTarget.files[0];
    let file = currentFile;
    let reader = new FileReader();
    reader.onload = function (d) {
      setUserData({ ...userData, cv: d.currentTarget.result });
    };
    reader.readAsDataURL(file);
  };

  const handleSelectBox = (index) => {
    if (selectedItems.selected) {
      setSelectedItems(selectedItemsStart);
    } else handleGetItems(index);
  };

  const handleAddressClick = () => {
    setInputActive({
      selected: true,
      value: 0,
    });
  };

  const handlePhoneClick = () => {
    setInputActive({
      selected: true,
      value: 1,
    });
  };

  const handleConvertText = (text) => {
    console.log(text);
    if (letterSize === undefined || letterSize === null) {
      return text;
    }
    if (letterSize === 0) {
      return text.toUpperCase();
    }
    if (letterSize === 1) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    }
    if (letterSize === 2) {
      return text.toLowerCase();
    }
  };

  const handleInput = () => {
    if (inputActive.value === 0)
      setUserData({ ...userData, address: inputRef.current.value });
    else setUserData({ ...userData, phone: inputRef.current.value });
    setInputActive({
      selected: false,
      value: -1,
    });
    inputRef.current.value = "";
  };

  return (
    <div className="profile-login">
      <div className="profile-select-items">
        {dataNames.map((data, index) => {
          return (
            <div className="profile-select-item" key={index}>
              <input
                type="text"
                placeholder={data}
                value={Object.values(userData.data)[index]}
                readOnly
              />
              <div
                className="profile-select-item-btn"
                onClick={() => handleSelectBox(index)}
              >
                <Button
                  typeButton={typeButton}
                  active={
                    selectedItems.selected && selectedItems.value === index
                  }
                >
                  <img
                    src={arrowImg}
                    alt=""
                    className="profile-select-item-img"
                  />
                </Button>
              </div>
              {selectedItems.selected && selectedItems.value === index && (
                <SelectBox
                  setSelectedItems={setSelectedItems}
                  selectedItems={selectedItems}
                  setUserData={setUserData}
                  userData={userData}
                  selectedItemsStart={selectedItemsStart}
                />
              )}
            </div>
          );
        })}
      </div>
      <div className="profile-select-buttons">
        <img src={locationImg} alt="" onClick={handleAddressClick} />
        <label htmlFor="file-upload" className="cv-image-container">
          <img src={cvImg} alt="" className="cv-image" />
        </label>
        <input id="file-upload" type="file" onChange={handleInputFile} />
        <img src={headphonesImg} alt="" onClick={handlePhoneClick} />
      </div>
      {!inputActive.selected && (
        <div className="profile-login-btn" onClick={handleClickBtn}>
          <Button activation={true} typeButton={typeButton}>
            <img src={sendImg} alt="" className="profile-login-btn-img" />
          </Button>
        </div>
      )}
      {inputActive.selected && inputActive.value === 0 && (
        <div className="profile-select-item-bottom">
          <input
            className={
              `font-size-${parseInt(
                fontSize === undefined || fontSize === null ? 0 : fontSize / 3
              )}` +
              ` font-style-${parseInt(
                fontStyle === undefined || fontStyle === null
                  ? 0
                  : fontStyle / 3
              )}`
            }
            style={
              boldItalic.find((element) => parseInt(element) === 2) !==
              undefined
                ? {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                    undefined &&
                  boldItalic.find((element) => parseInt(element) === 0) !==
                    undefined
                ? {
                    fontWeight: "bold",
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
                ? {
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined
                ? {
                    fontWeight: "bold",
                  }
                : {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
            }
            type="text"
            placeholder={handleConvertText("Adresa")}
            ref={inputRef}
            defaultValue={userData.address}
          />
          <div className="profile-select-item-btn" onClick={handleInput}>
            <Button activation={true} typeButton={typeButton}>
              <img src={plusImg} alt="" className="profile-select-item-img" />
            </Button>
          </div>
        </div>
      )}
      {inputActive.selected && inputActive.value === 1 && (
        <div className="profile-select-item-bottom">
          <input
            className={
              `font-size-${parseInt(
                fontSize === undefined || fontSize === null ? 0 : fontSize / 3
              )}` +
              ` font-style-${parseInt(
                fontStyle === undefined || fontStyle === null
                  ? 0
                  : fontStyle / 3
              )}`
            }
            type="text"
            placeholder={handleConvertText("Broj telefona")}
            ref={inputRef}
            defaultValue={userData.phone}
            style={
              boldItalic.find((element) => parseInt(element) === 2) !==
              undefined
                ? {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                    undefined &&
                  boldItalic.find((element) => parseInt(element) === 0) !==
                    undefined
                ? {
                    fontWeight: "bold",
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 0) !==
                  undefined
                ? {
                    fontStyle: "italic",
                  }
                : boldItalic.find((element) => parseInt(element) === 1) !==
                  undefined
                ? {
                    fontWeight: "bold",
                  }
                : {
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }
            }
          />
          <div className="profile-select-item-btn" onClick={handleInput}>
            <Button activation={true} typeButton={typeButton}>
              <img src={plusImg} alt="" className="profile-select-item-img" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileSelect;
