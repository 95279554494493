import { useGlobalContext } from "../../Context/Context";
import Button from "../../Button/Button";
import Visible from "../../picture/Visible.png";
import axios from "axios";

const RegularFile = ({
  setViewDocument,
  viewDocument,
  document,
  index,
  setDetailedFile,
  detailedFile,
}) => {
  const { baseUrl } = useGlobalContext();
  const { typeButton, leterSize } = useGlobalContext();
  const { apiKey, loggedUser, location } = useGlobalContext();
  const handleViewDocument = (document) => {
    try {
      axios
        .post(`${baseUrl}/location_documents/getDocumentData.php`, {
          user_id: loggedUser.id,
          location_id: location.location_id,
          api_key: apiKey,
          location_document_id: document.id,
        })
        .then((data) => {
          console.log("Poziva se i postavlja item list", data);
          if (data.data.status === 200) {
            console.log(data.data.data);

            setViewDocument({
              id: document.id,
              value: true,
              data: data.data.data,
              name: document.name,
            });
          }
        });
      //console.log(response);
    } catch (error) {
      console.log("Error fetching messages");
    }
  };

  const handleActiveFile = (index) => {
    console.log(index);
    console.log(detailedFile);
    if (detailedFile.find((file) => file === index))
      setDetailedFile(detailedFile.filter((file) => file !== index));
    else setDetailedFile([...detailedFile, index]);
  };
  console.log(viewDocument);
  return (
    <div className="regular-file">
      <div className="wrapper-regular" onClick={() => handleActiveFile(index)}>
        <span className="regular-file-number">{index}.</span>
        <span className="regular-file-name">{document.name}</span>
      </div>
      <div
        className="regular-file-btn"
        onClick={() => handleViewDocument(document)}
      >
        <Button typeButton={typeButton}>
          <img className="visible-img" src={Visible} alt=""></img>
        </Button>
      </div>
    </div>
  );
};

export default RegularFile;
