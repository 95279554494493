import Button from "../../Button/Button";
import "./folderModal.css";
import DetailedFile from "./DetailedFile";
import RegularFile from "./RegularFile";
import { useEffect, useState } from "react";
import plusImg from "../plus.png";
import sortImg from "../sortiranje.png";
import detailsImg from "../povecaj.png";
import folderImg from "../folder.png";
import axios from "axios";
import { useGlobalContext } from "../../Context/Context";
import UndoIcon from "@mui/icons-material/Undo";
import { MobilePDFReader } from "react-read-pdf";
import send from "../send.png";
import sizeOfFIle from "../../picture/SizeFile.png";
import { compareAsc, format } from "date-fns";
import { usePDF } from "@react-pdf/renderer";

const FolderModal = ({ folderActive, setFolderActive }) => {
  const { baseUrl } = useGlobalContext();
  const { apiKey, loggedUser, location, letterSize } = useGlobalContext();
  const { typeButton } = useGlobalContext();
  const { isIOS, isAndroid } = useGlobalContext();
  const [detailedFile, setDetailedFile] = useState([]);
  const [detailedActive, setDetailedActive] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [sortDocuments, setSortDocuments] = useState(false);
  const [sortingBySizeActive, setSortingBySizeActive] = useState(false);
  const [addDocument, setAddDocument] = useState(false);
  const [viewDocument, setViewDocument] = useState({
    id: 0,
    value: false,
    data: "",
    name: "",
  });

  const [document, setDocument] = useState({
    name: "",
    description: "",
    data: "",
  });
  const [lastModified, setLastModified] = useState("");

  const handleResetView = () => {
    setViewDocument({ id: 0, value: false, data: "", name: "" });
  };

  const handleSort = () => {
    let sortedDocuments = documents.sort((a, b) => {
      if (sortDocuments) return a.id - b.id;
      else return b.id - a.id;
    });
    setSortDocuments(!sortDocuments);
    sortingBySizeActive && setSortingBySizeActive(false);
    setDocuments(sortedDocuments);
  };

  const handleDocumentDetail = () => {
    setDetailedActive(!detailedActive);
    setDetailedFile([]);
  };

  const handleInputDocument = (e) => {
    const currentFile = e.currentTarget.files[0];
    let file = currentFile;
    let reader = new FileReader();
    reader.onload = function (d) {
      setDocument({
        ...document,
        data: d.currentTarget.result,
        name: currentFile.name,
      });
      setAddDocument(!addDocument);
    };
    reader.readAsDataURL(file);
  };

  const handleSendDocument = () => {
    if (document.data === "") return;
    try {
      axios
        .post(`${baseUrl}/location_documents/create.php`, {
          user_id: loggedUser.id,
          location_id: location.location_id,
          api_key: apiKey,
          name: document.name,
          data: document.data,
          description: document.description,
        })
        .then((data) => {
          if (data.data.status === 200) {
            const newDocument = {
              data: "",
              name: document.name,
              date: format(new Date(), "dd.MM.yyyy HH:mm"),
              id: data.data.id,
            };
            setDocuments([...documents, newDocument]);
            setAddDocument(false);
          }
        });
      //console.log(response);
    } catch (error) {
      console.log("Error fetching messages");
    }
  };

  const convertBytes = (bytes) => {
    let returnValue;
    //console.log(parseInt(bytes / Math.pow(1024, 2)));
    let i = 1;
    while (parseInt(bytes / Math.pow(1024, i)) > 1) i++;
    returnValue = Math.round(bytes / Math.pow(1024, i - 1), 2);
    switch (i) {
      case 1:
        returnValue += "B";
        break;
      case 2:
        returnValue += "KB";
        break;
      case 3:
        returnValue += "MB";
        break;
      case 4:
        returnValue += "GB";
        break;
    }
    return returnValue;
  };

  const sortBySize = () => {
    let sortedDocuments = documents.sort((a, b) => {
      if (sortingBySizeActive) return a.size - b.size;
      else return b.size - a.size;
    });
    setSortingBySizeActive(!sortingBySizeActive);
    sortDocuments && setSortDocuments(false);
    setDocuments(sortedDocuments);
  };

  const calculateFilesSize = () => {
    let size = 0;
    documents.forEach((document) => (size += document.size));
    console.log(size);
    return convertBytes(size);
  };

  const convertDate = (date) => {
    let convertedDate;
    const datePart = date.split(" ")[0];
    const hoursPart = date.split(" ")[1];
    const day = datePart.split(".")[0];
    const month = datePart.split(".")[1];
    const year = datePart.split(".")[2];
    const hours = hoursPart.split(":")[0];
    const minutes = hoursPart.split(":")[1];
    convertedDate = new Date(year, month, day, hours, minutes);
    return convertedDate;
  };

  const calculateIfNotSeen = () => {
    if (!lastModified) return;
    let unseen = [],
      extensions = [];
    let dateLastModified = convertDate(lastModified);
    documents.forEach((document) => {
      const dateDocument = convertDate(document.date);
      if (compareAsc(dateDocument, dateLastModified) === 1) {
        unseen.push(document);
      }
    });
    if (unseen.length !== 0)
      extensions = [...new Set(unseen.map((doc) => doc.extension))];
    let extensionsString = extensions.length !== 0 ? extensions.join() : "";
    if (extensionsString !== "")
      extensionsString = "(" + extensionsString + ")";
    const string = `${unseen.length} novih dokumenata ${extensionsString}`;
    return string;
  };
  const handleConvertText = (text) => {
    console.log(text);
    if (letterSize === undefined || letterSize === null) {
      return text;
    }
    if (letterSize === 0) {
      return text.toUpperCase();
    }
    if (letterSize === 1) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase();
    }
    if (letterSize === 2) {
      return text.toLowerCase();
    }
  };

  useEffect(() => {
    const handleGetDocuments = async () => {
      try {
        axios
          .post(`${baseUrl}/location_documents/getDocuments.php`, {
            user_id: loggedUser.id,
            location_id: location.location_id,
            api_key: apiKey,
          })
          .then((data) => {
            console.log("Poziva se vraca dokumenta", data.data.documents);
            if (data.data.status === 200) {
              setDocuments(data.data.documents);
              setLastModified(data.data.last_visited);
            } else {
              setDocuments([]);
            }
          });
        //console.log(response);
      } catch (error) {
        console.log("Error fetching messages");
      }
    };
    const handleUpdateSeen = async () => {
      try {
        axios.post(`${baseUrl}/location_documents/updateVisitedAt.php`, {
          user_id: loggedUser.id,
          location_id: location.location_id,
          api_key: apiKey,
        });
      } catch (error) {
        console.log("Error fetching messages");
      }
    };
    handleGetDocuments();
    return () => {
      handleUpdateSeen();
    };
  }, []);

  console.log(viewDocument);
  return (
    <div className="folder-modal-wrapper">
      <div className="folder-modal-header">
        <div className="folder-modal-header-btn">
          <label for="file-upload" class="custom-file-upload"></label>
          <input id="file-upload" type="file" onChange={handleInputDocument} />

          <Button typeButton={typeButton}>
            <img src={plusImg} alt="" className="folder-modal-header-img" />
          </Button>
        </div>
        <div className="folder-modal-header-btn" onClick={sortBySize}>
          <Button typeButton={typeButton}>
            <img src={sizeOfFIle} alt="" className="folder-modal-header-img" />
          </Button>
        </div>
        <div className="folder-modal-header-btn" onClick={handleSort}>
          <Button typeButton={typeButton}>
            <img src={sortImg} alt="" className="folder-modal-header-img" />
          </Button>
        </div>
        <div
          className="folder-modal-header-btn"
          onClick={() => handleDocumentDetail()}
        >
          <Button typeButton={typeButton}>
            <img src={detailsImg} alt="" className="folder-modal-header-img" />
          </Button>
        </div>
        <div
          className="folder-modal-header-btn"
          onClick={() => setFolderActive(false)}
        >
          <Button active={folderActive} typeButton={typeButton}>
            <img src={folderImg} alt="" className="folder-modal-header-img" />
          </Button>
        </div>
      </div>

      {!viewDocument.value ? (
        <>
          <div className="folder-modal-mail">
            <span className="folder-modal-mail-counter">
              {documents.length}.
            </span>
            <span className="folder-modal-mail-new-documents">
              {calculateIfNotSeen()}
            </span>
            <span className="folder-modal-mail-size">
              {calculateFilesSize()}
            </span>
          </div>
          {addDocument && (
            <div className="wrapper-add-documents">
              <div className="wrapper-description">
                <input
                  type="text"
                  onChange={(e) =>
                    setDocument({
                      ...document,
                      description: e.currentTarget.value,
                    })
                  }
                />
              </div>
              <div className="wrapper-title">
                <div
                  className="wrapper-title-button"
                  onClick={handleSendDocument}
                >
                  <input
                    type="text"
                    value={document.name}
                    onChange={(e) =>
                      setDocument({
                        ...document,
                        title: e.currentTarget.value,
                      })
                    }
                  />
                  <div className="wrapper-button-folder">
                    <Button typeButton={typeButton}>
                      <img className="visible-img" src={send}></img>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="folder-modal-folders-wrapper">
            <div className="folder-modal-folders">
              {documents !== undefined &&
                documents.map((file, index) => {
                  return (
                    <article className="folder-modal-file">
                      {detailedActive ||
                      detailedFile.find((file) => file === index) !==
                        undefined ? (
                        <DetailedFile
                          file={file}
                          index={index}
                          detailedFile={detailedFile}
                          setDetailedFile={setDetailedFile}
                          documents={documents}
                          setDocuments={setDocuments}
                        />
                      ) : (
                        <RegularFile
                          setViewDocument={setViewDocument}
                          viewDocument={viewDocument}
                          document={file}
                          index={index}
                          setDetailedFile={setDetailedFile}
                          detailedFile={detailedFile}
                        />
                      )}
                    </article>
                  );
                })}
            </div>
          </div>
        </>
      ) : (
        <div className="document-view-wrapper">
          <div className="wrapper-header-folder">
            <div className="full-screen" onClick={handleResetView}>
              <Button activation={true} typeButton={typeButton}>
                <UndoIcon />
              </Button>
            </div>
            <div className="document-title">{viewDocument.name}</div>
          </div>

          {!isAndroid && !isIOS ? (
            <iframe src={viewDocument.data} height="92%" width="100%" />
          ) : (
            viewDocument.data !== "" && (
              <MobilePDFReader
                url={viewDocument.data}
                height="90%"
                width="100%"
              ></MobilePDFReader>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default FolderModal;
